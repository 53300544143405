var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"#0f2e8c"}},[_c('h3',{staticClass:"white--text"},[_vm._v("QUẢN LÝ ĐƠN HÀNG ĐỐI TÁC CHỜ XÁC NHẬN")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.doDownloadList(true)}}},[_vm._v(" Tải lại dữ liệu ")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.Items,"search":_vm.search,"loading-text":"Đang tải dữ liệu...","footer-props":{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]},"items-per-page":20},scopedSlots:_vm._u([{key:"item.Transaction",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","color":"blue","disabled":_vm.loaderTransaction === item.Customer._id,"loading":_vm.loaderTransaction === item.Customer._id},on:{"click":function($event){return _vm.doLoadTransaction(item)}}},[_vm._v(" Chi tiết giao dịch ")])]}},{key:"item.Customer.Username",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.openCustomer(item)}}},[_vm._v(" "+_vm._s(item.Customer.Username)+" ")])]}},{key:"item.Account",fn:function(ref){
var item = ref.item;
return [(!item['Account'])?[_c('v-chip',{attrs:{"color":"orange","small":"","dark":""}},[_vm._v(" Chưa có thông tin ")])]:[_c('v-chip',{attrs:{"color":item.Yahoo || item.Shopping ? 'green':'blue',"small":"","dark":""}},[_vm._v(" "+_vm._s(item.Account)+" ")])]]}},{key:"item.Address",fn:function(ref){
var item = ref.item;
return [(!item['Address'])?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Chưa có địa chỉ ")]):_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(" "+_vm._s(item.AddressFull)+" ")])]}},{key:"item.Shipping",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":""}},'v-btn',attrs,false),on),[(item.Shipping)?[(item.Shipping === 'air')?_c('span',[_c('v-icon',[_vm._v("mdi-airplane")]),_c('span',[_vm._v("Air")])],1):_vm._e(),(item.Shipping === 'sea')?_c('span',[_c('v-icon',[_vm._v("mdi-ferry")]),_c('span',[_vm._v("Sea")])],1):_vm._e()]:[_c('span',[_vm._v("Chọn phương thức vận chuyển")])]],2)]}}],null,true)},[_c('v-list',[(item.Shipping !== 'air')?_c('v-list-item',[_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.doChangeShipping(item,'air')}}},[_c('v-icon',[_vm._v("mdi-airplane")]),_c('span',[_vm._v("Air")])],1)],1)],1):_vm._e(),(item.Shipping !== 'sea')?_c('v-list-item',[_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.doChangeShipping(item,'sea')}}},[_c('v-icon',[_vm._v("mdi-ferry")]),_c('span',[_vm._v("Sea")])],1)],1)],1):_vm._e()],1)],1)]}},{key:"item.Note",fn:function(ref){
var item = ref.item;
return [(Array.isArray(item.Notes) && item.Notes.length > 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":item['Notes'][0]['Staff'] ? 'green':'orange',"dark":"","small":""},on:{"click":function($event){return _vm.doGetNote(item)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item['Notes'][0]['Staff'] ? 'Ghi chú từ TE':'Ghi chú từ KH')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item['Notes'][0]['Staff'] ? 'TOM EXPRESS':'KHÁCH HÀNG')+" - Lúc: "+_vm._s(_vm.getCurrentTime(item['Notes'][0]['Date'])))])])]:[_c('v-btn',{attrs:{"color":"blue","dark":"","small":""},on:{"click":function($event){return _vm.doGetNote(item)}}},[_vm._v(" Chưa có ghi chú ")])]]}},{key:"item.Confirmed",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.doConfirmOrder(item)}}},[_vm._v(" Xác nhận ")]),(_vm.loginInfo['_perm'].toString() === 'admin')?_c('v-btn',{staticClass:"ma-1",attrs:{"dark":"","color":"red"},on:{"click":function($event){return _vm.doDeleteID(item['_id'])}}},[_vm._v(" Xoá ")]):_vm._e()]}},{key:"item.ProductStatus",fn:function(ref){
var item = ref.item;
return [(item.ProductStatus === 'da_nhap' && item['ProductData'] && item['ProductData']['productImages'] && item['ProductData']['productImages'].length > 0)?[_c('v-img',{attrs:{"lazy-src":item['ProductData']['productImages'][0]['uri'],"max-height":"100","max-width":"100","src":item['ProductData']['productImages'][0]['uri']}})]:_c('v-chip',{attrs:{"color":_vm.Status['ProductStatus'][item.ProductStatus].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.Status['ProductStatus'][item.ProductStatus].text)+" ")])]}},{key:"item.Link",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Chi tiết ")])]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-textarea',{attrs:{"label":"Link sản phẩm","readonly":"","rows":"1"},model:{value:(item.Link),callback:function ($$v) {_vm.$set(item, "Link", $$v)},expression:"item.Link"}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"text":"","color":"blue","href":item.Link,"target":"_blank"}},[_vm._v(" Mở ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":"Số lượng","readonly":""},model:{value:(item['Amount']),callback:function ($$v) {_vm.$set(item, 'Amount', $$v)},expression:"item['Amount']"}})],1)],1)],1)],1)],1)]}},{key:"item.Description",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"label":"Mô tả","readonly":"","rows":"1"},model:{value:(item.Description),callback:function ($$v) {_vm.$set(item, "Description", $$v)},expression:"item.Description"}})]}}],null,true)})],1)],1)],1)],1),_c('Transaction',{attrs:{"total":_vm.Transaction.Total,"closeState":_vm.boxTransaction,"account":_vm.Transaction.Account,"data":_vm.Transaction.Data,"loadingState":_vm.loaderTransaction},on:{"update:closeState":function($event){_vm.boxTransaction=$event},"update:close-state":function($event){_vm.boxTransaction=$event}}}),_c('Loading',{attrs:{"status":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }