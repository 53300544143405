<template>
	<v-container>
		<v-layout row wrap>
			<v-flex xs12>
				<v-card>
					<v-toolbar flat color="#0f2e8c">
						<h3 class="white--text">QUẢN LÝ ĐƠN HÀNG ĐỐI TÁC CHỜ XÁC NHẬN</h3>
						<v-spacer />
						<v-btn
							class="mx-2"
							color="blue"
							dark
							@click="doDownloadList(true)"
						>
							Tải lại dữ liệu
						</v-btn>
					</v-toolbar>
					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="Items"
							:search="search"
							loading-text="Đang tải dữ liệu..."
							:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
							:items-per-page="20"
						>
              <template v-slot:[`item.Transaction`]="{ item }">
                <v-btn
                  dark
                  color="blue"
                  @click="doLoadTransaction(item)"
                  :disabled="loaderTransaction === item.Customer._id"
                  :loading="loaderTransaction === item.Customer._id"
                >
                  Chi tiết giao dịch
                </v-btn>
              </template>
              <template v-slot:[`item.Customer.Username`]="{ item }">
                <a href="#"
                   @click="openCustomer(item)"
                >
                  {{item.Customer.Username}}
                </a>
              </template>
							<template v-slot:[`item.Account`]="{ item }">
								<template v-if="!item['Account']">
									<v-chip
										color="orange"
										small
										dark
									>
										Chưa có thông tin
									</v-chip>
								</template>
								<template v-else>
									<v-chip
										:color="item.Yahoo || item.Shopping ? 'green':'blue'"
										small
										dark
									>
										{{item.Account}}
									</v-chip>
								</template>
							</template>
							<template v-slot:[`item.Address`]="{ item }">
								<v-chip
									v-if="!item['Address']"
									color="red"
									dark
								>
									Chưa có địa chỉ
								</v-chip>
								<v-chip
									v-else
									color="blue"
									dark
								>
									{{item.AddressFull}}
								</v-chip>
							</template>
							<template v-slot:[`item.Shipping`]="{ item }">
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="blue"
											dark
											v-bind="attrs"
											v-on="on"
										>
											<template v-if="item.Shipping">
												<span v-if="item.Shipping === 'air'">
													<v-icon>mdi-airplane</v-icon>
													<span>Air</span>
												</span>
												<span v-if="item.Shipping === 'sea'">
													<v-icon>mdi-ferry</v-icon>
													<span>Sea</span>
												</span>
											</template>
											<template v-else>
												<span>Chọn phương thức vận chuyển</span>
											</template>
										</v-btn>
									</template>
									<v-list>
										<v-list-item  v-if="item.Shipping !== 'air'">
											<v-list-item-action>
												<v-btn
													color="blue"
													dark
													@click="doChangeShipping(item,'air')"
												>
													<v-icon>mdi-airplane</v-icon>
													<span>Air</span>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
										<v-list-item  v-if="item.Shipping !== 'sea'">
											<v-list-item-action>
												<v-btn
													color="blue"
													dark
													@click="doChangeShipping(item,'sea')"
												>
													<v-icon>mdi-ferry</v-icon>
													<span>Sea</span>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</v-menu>
							</template>
							<template v-slot:[`item.Note`]="{ item }">
								<template v-if="Array.isArray(item.Notes) && item.Notes.length > 0">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												:color="item['Notes'][0]['Staff'] ? 'green':'orange'"
												dark
												v-bind="attrs"
												v-on="on"
												small
												@click="doGetNote(item)"
											>
												{{item['Notes'][0]['Staff'] ? 'Ghi chú từ TE':'Ghi chú từ KH'}}
											</v-btn>
										</template>
										<span>{{item['Notes'][0]['Staff'] ? 'TOM EXPRESS':'KHÁCH HÀNG'}} - Lúc: {{getCurrentTime(item['Notes'][0]['Date'])}}</span>
									</v-tooltip>
								</template>
								<template v-else>
									<v-btn
										color="blue"
										@click="doGetNote(item)"
										dark
										small
									>
										Chưa có ghi chú
									</v-btn>
								</template>
							</template>
              <template v-slot:[`item.Confirmed`]="{ item }">
								<v-btn
                  dark
                  color="green"
                  @click="doConfirmOrder(item)"
                  class="ma-1"
                >
                  Xác nhận
                </v-btn>
                <v-btn
                    dark
                    color="red"
                    @click="doDeleteID(item['_id'])"
                    v-if="loginInfo['_perm'].toString() === 'admin'"
                    class="ma-1"
                >
                  Xoá
                </v-btn>
							</template>
							<template v-slot:[`item.ProductStatus`]="{ item }">
								<template v-if="item.ProductStatus === 'da_nhap' && item['ProductData'] && item['ProductData']['productImages'] && item['ProductData']['productImages'].length > 0">
									<v-img
										:lazy-src="item['ProductData']['productImages'][0]['uri']"
										max-height="100"
										max-width="100"
										:src="item['ProductData']['productImages'][0]['uri']"
									/>
								</template>
								<v-chip
									:color="Status['ProductStatus'][item.ProductStatus].color"
									dark
									v-else
								>
										{{Status['ProductStatus'][item.ProductStatus].text}}
								</v-chip>
							</template>
							<template v-slot:[`item.Link`]="{ item }">
								<v-menu
									:close-on-content-click="false"
									:nudge-width="200"
									offset-x
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="indigo"
											dark
											v-bind="attrs"
											v-on="on"
										>
											Chi tiết
										</v-btn>
									</template>
									<v-card>
										<v-list>
											<v-list-item>
												<v-list-item-content>
													<v-textarea
														v-model="item.Link"
														label="Link sản phẩm"
														readonly
														rows="1"
													/>
												</v-list-item-content>
												<v-list-item-action>
													<v-btn
														text
														color="blue"
														:href="item.Link"
														target="_blank"
													>
														Mở
													</v-btn>
												</v-list-item-action>
											</v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                              v-model="item['Amount']"
                              label="Số lượng"
                              readonly
                          />
                        </v-list-item-content>
                      </v-list-item>
										</v-list>
									</v-card>
								</v-menu>
							</template>
							<template v-slot:[`item.Description`]="{ item }">
								<v-textarea
									v-model="item.Description"
									label="Mô tả"
									readonly
									rows="1"
								/>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
    <Transaction :total="Transaction.Total" :closeState.sync="boxTransaction" :account="Transaction.Account" :data="Transaction.Data" :loadingState="loaderTransaction" />
		<Loading :status="loading" />
	</v-container>
</template>

<script>
import {Partner, PartnerPending} from '@/plugins/query/global';
import {Status} from '@/plugins/helper/dstatus';
import Query from '@/plugins/query/partner';
import Loading from '@/components/base/Loading.vue';
import {mapGetters} from "vuex";
import {DoiTien} from "@/plugins/helper/string";
import Swal from "sweetalert2";
import moment from 'moment-timezone';
import {serialize} from '@/plugins/helper/object';
import qShipment from "@/plugins/query/shipments.js";
import validations from '@/plugins/helper/validations'
import printJS from "print-js";
import Transaction from '@/components/customer/transaction.vue';
import {Request} from "@/plugins/helper/fetch";

export default {
	name: "Partner",
	components:{
		Loading,
    Transaction,
	},
	data(){
		return {
      req: new Request('customer'),
      boxTransaction: false,
      loaderTransaction: false,
      Transaction: {
        Account: null,
        Data: [],
        Total: 0,
      },
			totalNote:0,
			totalItems: 0,
			autoHide: false,
			latestPackage: null,
			packageData:{},
			shipmentID: null,
			loaderShipment: false,
			Shipments: [],
			boxShipment: false,
			EditorMode: 0,
			ListAddress:[],
			loadAddress: false,
			boxAddress: false,
			Selected:[],
			boxNote: false,
			boxImage: false,
			Image: null,
			uploadID: null,
			selectedFile: null,
			onUploadProgress: 0,
			menu:{
				created_at: false,
				updated_at: false,
			},
			NoteData:{
				ID: null,
				Title:null,
				Notes:[]
			},
			NewNoteContent: null,
			filter:{
        Confirmed: null,
				System: null,
				Note: null,
				Account: null,
				ProductStatus: null,
				Customer: null,
				Staff: null,
				Paid: null,
				Purchased: null,
				Imported: null,
				Shipping: 'all',
				created_at: [],
				updated_at: [],
				Search: null,
			},
			loadDRC: false,
			loadKL: false,
			loadPhi: false,
			loadNote: false,
			search:null,
			loading: false,
			items: [],
			editMode:{
				KL: false,
				DRC: false,
				Phi: false,
				PhiShip: false,
			},
			editData: {
			},
			headers:[
				{ text: 'Xác nhận', value: 'Confirmed', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Link SP', value: 'Link', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'ID Đơn hàng', value: 'Order', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'ID Sản phẩm', value: 'Product', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Tài khoản', value: 'Account', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Khách hàng', value: 'Customer.Username', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Giao dịch', value: 'Transaction', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Sale', value: 'Customer.Sale.Username', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Vận chuyển', value: 'Shipping', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Địa chỉ', value: 'Address', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Mô tả', value: 'Description', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Thông tin SP', value: 'ProductStatus', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Ghi chú', value: 'Note', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'T.gian tạo', value: 'created_at', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'T.gian c.nhật', value: 'updated_at', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
			],
			ListSearch:{
        Confirmed:[
          {value:null,text:'Tất cả'},
          {value:true,text:'Đã xác nhận'},
          {value:false,text:'Chưa xác nhận'},
        ],
				System:[
					{value:null,text:'Tất cả'},
					{value:'system',text:'Đơn hàng Đấu giá - Shopping'},
					{value:'auctions',text:'Đơn hàng Đấu giá'},
					{value:'shopping',text:'Đơn hàng Shopping'},
					{value:false,text:'Đơn hàng khác'},
				],
				Note:[
					{value:null,text:'Tất cả'},
					{value:true,text:'Phản hồi từ khách hàng'},
				],
				ProductStatus:[
					{value:null,text:'Tất cả'},
					{value:'dang_cho',text:'Đang chờ'},
					{value:'da_nhap',text:'Đã nhập'},
					{value:'khong_ho_tro',text:'Không hỗ trợ'},
					{value:'bi_loi',text:'Lỗi'},
				],
				Paid:[
					{value:null,text:'Tất cả'},
					{value:false,text:'Chưa thanh toán'},
					{value:true,text:'Đã thanh toán'},
				],
				Purchased:[
					{value:null,text:'Tất cả'},
					{value:false,text:'Đang xử lí'},
					{value:true,text:'Đã mua hàng'},
				],
				Imported:[
					{value:null,text:'Tất cả'},
					{value:'cho_nhap_kho',text:'Chưa nhập kho'},
					{value:'da_nhap_kho',text:'Đã nhập kho'},
					{value:'da_xuat_kho',text:'Đã xuất kho'},
				],
			},
			Status:{
				ProductStatus:{
					dang_cho:{
						text:'Đang chờ',
						color:'blue'
					},
					da_nhap:{
						text:'Đã nhập',
						color:'green'
					},
					khong_ho_tro:{
						text:'Không hỗ trợ',
						color:'red'
					},
					bi_loi:{
						text:'Lỗi',
						color:'orange'
					},
				},
        Confirmed:{
          false:{
            text:'Chưa xác nhận',
            color:'red',
          },
          true:{
            text:'Đã xác nhận',
            color:'green',
          },
        },
				Paid:{
					false:{
						text:'Chưa thanh toán',
						color:'red',
					},
					true:{
						text:'Đã thanh toán',
						color:'green',
					},
				},
				Purchased:{
					false:{
						text:'Đang xử lí',
						color:'orange',
					},
					true:{
						text:'Đã mua hàng',
						color:'green',
					},
				},
				Status:{
					'cho_nhap_kho':{
						text:'Chưa nhập kho',
						color:'red',
					},
					'da_nhap_kho':{
						text:'Đã nhập kho',
						color:'green',
					},
					'da_xuat_kho':{
						text:'Đã xuất kho',
						color:'blue',
					}
				},
			},
			PackageType: [
				{code:'carton',text:'Loose carton'},
				{code:'pallet',text:'Pallet'},
				{code:'crate',text:'Wooden crate'},
				{code:'bag',text:'Bag'},
			],
			StaffList: [],
			CustomerList:[],
			AccountList:[],
			SumShip: 0,
			SumPhi: 0,
			timeOut: null,
		}
	},
	computed:{
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		isAllowedPartner(){
			if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager'){
				return {
					Partner: true,
					Auction: true,
				};
			}
			if(this.loginInfo._perm === 'warehouse'){
				return {
					Partner: true,
					Auction: false,
				};
			}
			if(this.loginInfo._perm === 'sale') {
				if(this.loginInfo.Limited === false){
					return this.loginInfo.Rules;
				}
			}
			return false;
		},
		ItemsPackage(){
			const {autoHide, Items, CurrentPackageCustomer, CurrentPackageAddress, CurrentPackageShipping} = this;
			// if(autoHide) return Items
			// 	.filter(item=>item['AddressFull'])
			// 	.filter(item=>item['Status'] === 'da_nhap_kho')
			// 	.filter(item=>item['ProductStatus'] === 'da_nhap')
			// 	.filter(item=>item['Customer']['_id'] === CurrentPackageCustomer || CurrentPackageCustomer === null)
			// 	.filter(item=>item['Address']['Index'] === CurrentPackageAddress || CurrentPackageAddress === null)
			// 	.filter(item=>item['Shipping'] === CurrentPackageShipping || CurrentPackageShipping === null)
			return Items;
		},
		isPackageValid(){
			const {Code, Type} = this.packageData;
			return Code && Type;
		},
		NW(){
			let {D,R,C} = this.packageData;
			D = parseInt(D); R = parseInt(R); C = parseInt(C);
			D = isNaN(D) ? 0: D; R = isNaN(R) ? 0:R; C = isNaN(C) ? 0:C;
			return (D*R*C/6000).toFixed(2);
		},
		Shipment(){
			if(this.Shipments.length === 0) return {};
			if(!this.shipmentID) return {};
			const index = this.Shipments.findIndex(item=>item._id === this.shipmentID);
			if(index > -1){
				return this.Shipments[index];
			}
			return {};
		},
		AutoCode(){
			const {Shipment, packageData} = this;
			if(Shipment.Code){
				const Code = (packageData.Code) ? packageData.Code : '';
				return `${Shipment.NameAuto}-${Code}-1/1`;
			}
			return 'Chưa có dữ liệu';
		},
		CurrentPackageSale(){
			const List = this.Selected;
			if(List.length > 0){
				return List[0]['Customer']['Sale']['Username']
			}
			return null;
		},
		CurrentPackageCustomerUsername(){
			const List = this.Selected;
			if(List.length > 0){
				return List[0]['Customer']['Username']
			}
			return null;
		},
		CurrentPackageShipping(){
			const List = this.Selected;
			if(List.length > 0){
				return List[0]['Shipping']
			}
			return null;
		},
		CurrentPackageCustomer(){
			const List = this.Selected;
			if(List.length > 0){
				return List[0]['Customer']['_id']
			}
			return null;
		},
		CurrentPackageAddress(){
			const List = this.Selected;
			if(List.length > 0){
				return List[0]['Address']['Index']
			}
			return null;
		},
		ListAddressData(){
			return this.ListAddress.map((item,index)=>({
				...item,
				index,
				Full: `${item.Name}, ${item.Address}, ${item.Phone}`,
			}));
		},
/*		SumShip(){
			const initialValue = 0;
			const items = this.Items.map(i=>i.PhiShipNoiDia);
			const sumWithInitial = items.reduce(
				(previousValue, currentValue) => previousValue + currentValue,
				initialValue
			);
			return sumWithInitial;
		},*/
/*		SumPhi(){
			const initialValue = 0;
			const items = this.Items.map(i=>i.Phi);
			const sumWithInitial = items.reduce(
				(previousValue, currentValue) => previousValue + currentValue,
				initialValue
			);
			return sumWithInitial;
		},*/
		CreatedAt() {
			const data = this.filter.created_at;
			if(data.length > 0) {
				return this.filter.created_at.join(' ~ ')
			}
			return 'Lựa chọn thời gian'
		},
		UpdatedAt() {
			const data = this.filter.updated_at;
			if(data.length > 0) {
				return this.filter.updated_at.join(' ~ ')
			}
			return 'Lựa chọn thời gian'
		},
		notice(){
			return new Status(this.$swal);
		},
		Items(){
			const {Customer, Paid, Purchased, Imported, Shipping, created_at, updated_at, ProductStatus, Account, Note} = this.filter;
			const localtz = moment.tz.guess();
			let filter_by_created = false; let filter_by_updated = false;
			if(created_at.length > 0){
				filter_by_created = {start:moment(created_at[0]).startOf('day').toISOString(), end: created_at[1] ? moment(created_at[1]).endOf('day').toISOString():moment(created_at[0]).endOf('day').toISOString()};
			}
			if(updated_at.length > 0){
				filter_by_updated = {start:moment(updated_at[0]).startOf('day').toISOString(), end:updated_at[1] ? moment(updated_at[1]).endOf('day').toISOString():moment(updated_at[0]).endOf('day').toISOString()};
			}
			const items = this.items.map(item=>{
				return {
					...item,
					created_at_raw:item.created_at,
					updated_at_raw:item.updated_at,
					created_at: moment(item.created_at).tz(localtz).format("DD-MM-YYYY HH:mm"),
					updated_at: moment(item.updated_at).tz(localtz).format("DD-MM-YYYY HH:mm"),
					PTime: item.PTime ? moment(item.PTime).tz(localtz).format("DD-MM-YYYY HH:mm"): null,
					AddressFull: item.Address && item.Address.Name ? `${item['Address']['Name']} - ${item['Address']['Phone']}`:undefined,
				}
			});
			return items;
				/*.filter(f=>f.Customer._id === Customer || Customer === null)
					.filter(f=>f.Paid === Paid || Paid === null)
						.filter(f=>f.Purchased === Purchased || Purchased === null)
							.filter(f=>f.Status === Imported || Imported === null)
								.filter(f=>f.Shipping === Shipping || Shipping === 'all')
									.filter(f=>f.ProductStatus === ProductStatus || ProductStatus === null)
										.filter(f=>f.Account === Account || Account === null)
											.filter(f=>f['Notes'][0] && f['Notes'][0]['Customer'] || Note === null)
												.filter(f=>filter_by_created === false || (f.created_at_raw >= filter_by_created['start'] && f.created_at_raw <= filter_by_created['end']))
													.filter(f=>filter_by_updated === false || (f.updated_at_raw >= filter_by_updated['start'] && f.updated_at_raw <= filter_by_updated['end']))*/
												/*.filter(function (a) {
													//const created_at = new Date(a.created_at_raw);
													const created_at = a.created_at;
													const result = filter_by_created === false || (created_at >= filter_by_created['start'] && created_at <= filter_by_created['end']);
													return result;
												})*/
													/*.filter(function (a) {
														//const updated_at = new Date(a.updated_at_raw);
														const updated_at = a.updated_at;
														return filter_by_updated === false || (updated_at >= filter_by_updated['start'] && updated_at <= filter_by_updated['end'])
													});*/
		},
		CustomerListData(){
			let customer = [{_id:null,Username:'Tất cả'}];
			return customer.concat(this.CustomerList);
		},
		StaffListData(){
			let staff = [{_id:null,Username:'Tất cả'}];
			return staff.concat(this.StaffList);
		},
/*		AccountList(){
			let Accounts = [];
			this.Items.map(item=>{
				if(item['Account']){
					if(!Accounts.includes(item['Account'])) Accounts.push(item['Account'])
				}
			});
			return Accounts;
		},*/
	},
	watch: {
		/*filter: {
			handler () {
				const {filter} = this;
				const {Note} = filter;
				if(Note === true){
					this.filter.Customer = null;
					this.filter.Account = null;
				}
				this.doDownloadList();
			},
			deep: true,
		},*/
	},
	methods:{
		...validations,
		...Query,
		DoiTien,
    doLoadTransaction(item){
      const ID = item.Customer._id;
      this.Transaction.Account = item.Customer.Username;
      const Transactions = item.Transactions;
      this.boxTransaction = true;
      this.loaderTransaction = ID;
      this.req.post(`transaction/${ID}`,{Transactions}).then(async resp=>{
        const json = await resp.json();
        if(json.data){
          this.Transaction.Data = json.data;
          this.Transaction.Total = json.total;
        }
        if(json.error){
          const msg = json.error || 'Có lỗi khi tải giao dịch !!!';
          this.notice.error(msg);
        }
      })
      .catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      })
      .finally(()=>{
        this.loaderTransaction = false;
      });
    },
    openCustomer(item){
      let routeData = this.$router.resolve({name: 'customer', query: {username: item.Customer.Username}});
      return window.open(routeData.href, '_blank');
    },
    doConfirmOrder(item){
      const app = this;
      return this.notice.confirm('Xác nhận ?','Bạn có xác nhận giao dịch này ? Hành động này không thể phục hồi !!!').then(success=> {
        if (success) {
          app.loading = true;
          app.qrConfirm(item['_id']).then(response => {
            const {error} = response;
            if (error) {
              app.notice.error(error);
              return;
            }
            app.notice.success('Đã xác nhận thành công !!!');
            app.doResetList();
            app.doDownloadList();
          })
          .finally(() => {
            app.loading = false;
          })
        }
      });
    },
		doClickSearch(){
			const {filter} = this;
			const {Note} = filter;
			if(Note === true){
				this.filter.Customer = null;
				this.filter.Account = null;
			}
			this.doDownloadList();
		},
		doSearch(){
			clearTimeout(this.timeOut);
			const app = this;
			this.timeOut = setTimeout(() => {
				app.filter.Search = app.search;
			}, 300);
		},
		doDeleteList(){
			this.loading = true;
			const app = this;
			const DeleteItems = this.Selected.map(item=>item._id);
			this.notice.confirm('Xác nhận ?','Hành động này sẽ thay đổi dữ liệu và không thể phục hồi. Bạn có xác nhận ?').then(success=> {
				if (success) {
					app.qrDelete(DeleteItems).then(response => {
						const {error} = response;
						if (error) {
							app.notice.error(error);
							return;
						}
						app.notice.success('Đã xóa thành công !!!');
						app.doResetList();
						app.doDownloadList();
					})
						.finally(() => {
							app.loading = false;
						})
				} else {
					app.loading = false;
				}
			});
		},
		doDeleteID(id){
			this.loading = true;
			const app = this;
			this.notice.confirm('Xác nhận ?','Hành động này sẽ thay đổi dữ liệu và không thể phục hồi. Bạn có xác nhận ?').then(success=> {
				if (success) {
					app.qrDelete(id).then(response => {
						const {error} = response;
						if (error) {
							app.notice.error(error);
							return;
						}
						app.notice.success('Đã xóa thành công !!!');
						app.doResetList();
						app.doDownloadList();
					})
					.finally(() => {
						app.loading = false;
					})
				} else {
					app.loading = false;
				}
			});
		},
		getCurrentTime(time){
			const local = moment.tz.guess();
			return moment(time).tz(local).format("DD-MM-YYYY HH:mm");
		},
		doMergeOrder(){
			const {Selected, Shipment, AutoCode, packageData, NW} = this;
			if(Selected.length > 0 && Shipment['_id'] && AutoCode && packageData['GW'] && packageData['D'] && packageData['R'] && packageData['C'] && packageData['Type'] && NW){
				this.loaderShipment = true;
				const {D, R, C, Type, GW} = packageData;
				const query = {
					Items: Selected.map(item=>item['_id']),
					Shipment: Shipment['_id'],
					Code: AutoCode,
					D,
					R,
					C,
					GW,
					NW,
					Type
				}
				this.qrMerge(query).then(response=>{
					const {error} = response;
					if(error){
						this.notice.error(error);
						return;
					}
					const pdfUrl = URL.createObjectURL(response);
					printJS(pdfUrl);
					this.boxShipment = false;
					this.packageData = {};
					this.doResetList();
					this.doDownloadList();
				})
				.finally(()=>{
					this.loaderShipment = false;
				})
			}
		},
		doPrintWithOutAdd(){
			const {Shipment, CurrentPackageCustomerUsername, CurrentPackageSale, packageData, AutoCode, NW} = this;
			if(Shipment['Type'] && CurrentPackageCustomerUsername !== null && CurrentPackageSale !== null && packageData['GW'] && AutoCode && NW) {
				const query = {
					Code: AutoCode,
					Customer: CurrentPackageCustomerUsername,
					Sale: CurrentPackageSale,
					KL: packageData['GW'].toString(),
					TT: NW.toString(),
					Shipping: Shipment['Type']
				}
				this.loaderShipment = true;
				this.qrPDF(query).then(resp=>{
					const {error} = resp;
					if(error){
						this.notice.error(error);
						return;
					}
					const pdfUrl = URL.createObjectURL(resp);
					printJS(pdfUrl);
				}).finally(()=>{
					this.loaderShipment = false;
				})
			}

		},
		doGetShipmentPackage(){
			if(!this.shipmentID) return;
			this.loaderShipment = true;
			qShipment.qrLastPackage(this.shipmentID).then(resp=>{
				const {data} = resp;
				this.latestPackage = data.Code;
			}).finally(()=>{
				this.loaderShipment = false;
			});
		},
		doDownloadListShipments(){
			this.loaderShipment = true;
			return qShipment.qrSearch(this.CurrentPackageShipping).then(resp=>{
				const {data} = resp;
				this.Shipments = data;
				this.shipmentID = null;
			}).finally(()=>{
				this.loaderShipment = false;
			});
		},
		doDownloadShipment(){
			if(this.filter.Customer !== null && this.Selected.length > 0){
				const app = this;
				app.latestPackage = null;
				app.boxShipment = true;
				app.doDownloadListShipments();
			}
		},
		doResetList(){
			this.Selected = [];
			this.autoHide = false;
		},
		doSelectPackageAddress(item){
			const {Selected} = this;
			const index = Selected.indexOf(item);
			if(index > -1){
				this.Selected.splice(index,1);
			}else{
				this.Selected.push(item);
			}
		},
		doSelectPackage(item){
			const {CurrentPackageCustomer, CurrentPackageAddress, CurrentPackageShipping, Selected} = this;
			if(CurrentPackageCustomer !== null){
				if(item['Customer']['_id'] !== CurrentPackageCustomer){
					this.notice.error('Khách hàng không trùng khớp !!!');
					return;
				}
			}
			if(CurrentPackageAddress !== null){
				if(item['Address']['Index'] !== CurrentPackageAddress){
					this.notice.error('Địa chỉ không trùng khớp !!!');
					return;
				}
			}
			if(CurrentPackageShipping !== null){
				if(item['Shipping'] !== CurrentPackageShipping){
					this.notice.error('Phương thức vận chuyển không trùng khớp !!!');
					return;
				}
			}
			const index = Selected.indexOf(item);
			if(index > -1){
				this.Selected.splice(index,1);
			}else{
				this.Selected.push(item);
			}
		},
		doSelectAddressByStatus(Status){
			this.Selected = this.Items.filter(f=>f.Status === Status);
		},
		doSelectAddress(IsAddress){
			this.Selected = IsAddress ? this.Items.filter(f=>f.AddressFull):this.Items.filter(f=>!f.AddressFull);
		},
		doUpdateAddress(index){
			if(this.filter.Customer === null){
				this.notice.error('Thông tin khách hàng không hợp lệ !!!');
				this.boxAddress = false;
				return;
			}
			const app = this;
			app.notice.confirm('Xác nhận ?','Hành động này sẽ thay đổi dữ liệu và không thể phục hồi. Bạn có xác nhận ?').then(success=>{
				if(success){
					const query = {
						Address: index,
						Items:this.Selected.map(item=>item._id)
					}
					app.loadAddress = true;
					app.qrUpdateAddress(this.filter.Customer, query).then(json=>{
						const {success, error} = json;
						if(error){
							app.notice.error(error);
						}
						if(success){
							app.notice.success('Đã cập nhật địa chỉ thành công !!!');
							app.boxAddress = false;
							app.Selected = [];
							app.doDownloadList();
						}
					})
					.catch(e=>{
						console.error(e);
						app.notice.error('Không thể cập nhật địa chỉ !!!');
						app.boxAddress = false;
					}).finally(()=>{
						app.loadAddress = false;
					})
				}
			})
		},
		doDownloadCustomerList(){
			if(this.filter.Customer !== null){
				const app = this;
				app.loadAddress = true;
				app.boxAddress = true;
				app.qrGetAddressList(this.filter.Customer).then(response =>{
					return response.json();
				})
				.then(json=>{
					app.ListAddress = json.data;
				})
				.catch(e=>{
					console.error(e);
					app.notice.error('Không thể tải danh sách địa chỉ !!!');
					app.boxAddress = false;
				}).finally(()=>{
					app.loadAddress = false;
				})
			}
		},
		doLoadNote(){
			const {NoteData} = this;
			if(NoteData.ID){
				const app = this;
				app.loadNote = true;
				app.qrGetNote(NoteData.ID).then(response =>{
					return response.json();
				}).then(json=>{
					app.NoteData['Notes'] = json.data;
				}).catch(e=>{
					console.error(e);
					app.notice.error('Không thể tải thông tin ghi chú !!!');
					app.boxNote = false;
				}).finally(()=>{
					app.loadNote = false;
				})
			}
		},
		doGetNote(item){
			this.NoteData = {
				ID: item._id,
				Title: `${item.Product}-${item.Order}`,
				Notes: [],
			};
			this.boxNote = true;
			this.doLoadNote();
		},
		doAddNote(){
			const {NewNoteContent, NoteData} = this;
			if(NewNoteContent){
				const request = {
					note: NewNoteContent
				};
				const app = this;
				app.loadNote = true;
				this.qrAddNote(NoteData.ID,request).then(response=>{
					return response.json();
				})
				.then(json=>{
					const {success, error} = json;
					if(error){
						app.notice.error(error);
					}
					if(success){
						app['NewNoteContent'] = null;
						setTimeout(app.doLoadNote(),100);
					}
				})
				.catch(e=>{
					console.error(e);
					app.notice.error('Không thể thêm ghi chú !!!');
				})
				.finally(()=>{
					app.loadNote = false;
				})
			}else{
				this.notice.error('Không có nội dung ghi chú !!!');
			}
		},
		doExportExcel(ExportSale=false){
			const {filter} = this;
			const {Customer, Status, Purchased, Paid, created_at, updated_at, Shipping, Imported, Account, Staff} = filter;
			let Filter = {Customer, Status, Purchased, Paid, created_at, updated_at, Shipping, Imported, Account, Staff};
			if(ExportSale){
				Filter['ExportMode'] = 'admin';
			}
			const query = serialize(Filter);
			const app = this;
			this.qrExportExcel(query).then(response => {
				const contentType = response.headers.get("content-type");
				if (contentType && contentType.indexOf("application/json") !== -1) {
					return response.json().then(json => {
						const {error} = json;
						if(error){
							app.notice.error(error);
						}
					});
				} else {
					return response.blob().then(blob => {
						const url = window.URL.createObjectURL(blob);
						const a = document.createElement('a');
						a.href = url;
						a.download = "xuat-doitac.xlsx";
						document.body.appendChild(a);
						a.click();
						a.remove();
					});
				}
			})
		},
		doCancelFilterByUpdated(){
			const vm = this;
			vm.filter['updated_at'] = [];
			vm.menu.updated_at = false;
			//vm.$forceUpdate();
			vm.$refs.menu_updated_at.save(vm.filter.updated_at)
		},
		doCancelFilterByCreated(){
			const vm = this;
			vm.filter['created_at'] = [];
			vm.$forceUpdate();
			vm.menu.created_at = false;
			vm.$refs.menu_created_at.save(vm.filter.created_at)
		},
		doChangeShipping(item, Shipping){
			this.loading = true;
			const {_id} = item;
			const app = this;
			this.notice.confirm("Xác nhận ?","Bạn có xác nhận thay đổi ?").then(result=>{
				if(result){
					app.qrSaveShipping(_id,Shipping).then(resp=>{
						return resp.json();
					}).then(json=>{
						const {success, error} = json;
						if(success){
							const index = app.items.findIndex(f=>f._id===_id);
							app.items[index].Shipping = Shipping;
							app.notice.success('Đã cập nhật dữ liệu !!!');
						}
						if(error){
							app.notice.error(error);
						}
					}).catch(e=>{
						console.log(e);
						app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
					}).finally(()=>{
						this.loading = false;
					})
				}else{
					this.loading = false;
				}
			});
		},
		doChangeStatus(item,Status){
			this.loading = true;
			const {_id} = item;
			const app = this;
			this.notice.confirm("Xác nhận ?","Bạn có xác nhận thay đổi ?").then(result=>{
				if(result){
					app.qrSaveStatus(_id,Status).then(resp=>{
						return resp.json();
					}).then(json=>{
						const {success, error} = json;
						if(success){
							const index = app.items.findIndex(f=>f._id===_id);
							app.items[index].Status = Status;
							app.notice.success('Đã cập nhật dữ liệu !!!');
						}
						if(error){
							app.notice.error(error);
						}
					}).catch(e=>{
						console.log(e);
						app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
					}).finally(()=>{
						this.loading = false;
					})
				}else{
					this.loading = false;
				}
			});
		},
		doViewImage(item){
			if(!item.Image){
				Swal.fire({
					type:'error',
					title:'Lỗi',
					text:'Đơn hàng này không có hình ảnh !!!'
				})
				return;
			}
			this.Image = item.Image;
			this.boxImage = true;
		},
		LinkHinhAnh(item){
			if(this.uploadID === item._id && this.selectedFile) return URL.createObjectURL(this.selectedFile)
			const image = item.Image
			if(!image) return '/no-image.png'
			return `https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/${image}`
		},
		cancelUpload(){
			this.uploadID = undefined;
		},
		onUpload(item) {
			this.uploadID = item._id
			this.$refs.uploader.click()
		},
		handleFileUpload(e){
			const file = e.target.files && e.target.files.length ? e.target.files[0] : null
			if (file) {
				this.selectedFile = file;
				this.doUploadImage()
			}
		},
		doUploadImage(){
			const {uploadID} = this;
			this.qrUploadImage(uploadID,this.selectedFile,(event) => {
				this.onUploadProgress = Math.round((100 * event.loaded) / event.total);
			}).then(axios=>{
				const {success,error,data} = axios.data
				if(success){
					this.notice.success("Đã tải hình ảnh thành công !!!")
					const index = this.items.findIndex(f=>f._id===uploadID);
					this.items[index].Image = data.uri
				}else{
					const e = error || "Không thể tải lên hình ảnh !!!"
					this.notice.error(e)
				}
			})
				.catch(e=>{
					this.notice.error(e)
				})
				.finally(()=>{
					this.uploadID = null;
					this.selectedFile = null;
					this.onUploadProgress = 0;
				})
		},
		doUpdatePurchased(item){
			this.loading = true;
			const {_id, Purchased} = item;
			const app = this;
			this.notice.confirm("Xác nhận ?","Bạn có xác nhận thay đổi ?").then(result=>{
				if(result){
					app.qrSavePurchased(_id,!Purchased).then(resp=>{
						return resp.json();
					}).then(json=>{
						const {success, error} = json;
						if(success){
							const index = app.items.findIndex(f=>f._id===_id);
							app.items[index].Purchased = !Purchased;
							app.notice.success('Đã cập nhật dữ liệu !!!');
						}
						if(error){
							app.notice.error(error);
						}
					}).catch(e=>{
						console.log(e);
						app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
					}).finally(()=>{
						this.loading = false;
					})
				}else{
					this.loading = false;
				}
			});
		},
		doUpdatePaid(item){
			this.loading = true;
			const {_id, Paid} = item;
			const app = this;
			this.notice.confirm("Xác nhận ?","Bạn có xác nhận thay đổi ?").then(result=>{
				if(result){
					app.qrSavePaid(_id,!Paid).then(resp=>{
						return resp.json();
					}).then(json=>{
						const {success, error} = json;
						if(success){
							const index = app.items.findIndex(f=>f._id===_id);
							app.items[index].Paid = !Paid;
							app.notice.success('Đã cập nhật dữ liệu !!!');
						}
						if(error){
							app.notice.error(error);
						}
					}).catch(e=>{
						console.log(e);
						app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
					}).finally(()=>{
						this.loading = false;
					})
				}else{
					this.loading = false;
				}
			});
		},
		doUpdatePhiShip(item){
			this.editData = JSON.parse(JSON.stringify(item));
			this.editMode.PhiShip = true;
		},
		doSavePhiShip(){
			const {_id, PhiShipNoiDia} = this.editData;
			if(_id !== undefined && PhiShipNoiDia !== undefined){
				this.loadPhi = true;
				this.qrSavePhiShip(_id,PhiShipNoiDia).then(resp=>{
					return resp.json();
				}).then(json=>{
					const {success,error} = json;
					if(success){
						const index = this.items.findIndex(f=>f._id === _id);
						this.items[index].PhiShipNoiDia = PhiShipNoiDia;
						this.notice.success('Đã cập nhật thông tin thành công !!!');
						this.editMode.PhiShip = false;
						this.editData = {};
					}
					if(error){
						this.notice.error(error);
					}
				})
					.catch(err=>{
						console.log(err);
						this.notice.error(err.message || 'Không thể cập nhật thông tin !!!');
					})
					.finally(()=>{
						this.loadPhi = false;
					})
			}
			else{
				this.notice.error('Dữ liệu không hợp lệ !!!');
			}
		},
		doUpdatePhi(item){
			this.editData = JSON.parse(JSON.stringify(item));
			this.editMode.Phi = true;
		},
		doSavePhi(){
			const {_id, Phi} = this.editData;
			if(_id !== undefined && Phi !== undefined){
				this.loadPhi = true;
				this.qrSavePhi(_id,Phi).then(resp=>{
					return resp.json();
				}).then(json=>{
					const {success,error} = json;
					if(success){
						const index = this.items.findIndex(f=>f._id === _id);
						this.items[index].Phi = Phi;
						this.notice.success('Đã cập nhật thông tin thành công !!!');
						this.editMode.Phi = false;
						this.editData = {};
					}
					if(error){
						this.notice.error(error);
					}
				})
					.catch(err=>{
						console.log(err);
						this.notice.error(err.message || 'Không thể cập nhật thông tin !!!');
					})
					.finally(()=>{
						this.loadPhi = false;
					})
			}
			else{
				this.notice.error('Dữ liệu không hợp lệ !!!');
			}
		},
		doSaveDRC(){
			const {_id, D, R, C} = this.editData;
			if(_id !== undefined && D !== undefined && R !== undefined && C !== undefined){
				this.loadKL = true;
				this.qrSaveDRC(_id,{D, R, C}).then(resp=>{
					return resp.json();
				}).then(json=>{
					const {success,error} = json;
					if(success){
						const index = this.items.findIndex(f=>f._id === _id);
						this.items[index].D = D;
						this.items[index].R = R;
						this.items[index].C = C;
						this.items[index].Volumn = (D*R*C)/6000;
						this.notice.success('Đã cập nhật thông tin thành công !!!');
						this.editMode.DRC = false;
						this.editData = {};
					}
					if(error){
						this.notice.error(error);
					}
				})
					.catch(err=>{
						console.log(err);
						this.notice.error(err.message || 'Không thể cập nhật thông tin !!!');
					})
					.finally(()=>{
						this.loadKL = false;
					})
			}
			else{
				this.notice.error('Dữ liệu không hợp lệ !!!');
			}
		},
		doUpdateDRC(item){
			this.editData = JSON.parse(JSON.stringify(item));
			this.editMode.DRC = true;
		},
		doSaveKL(){
			const {_id, KL} = this.editData;
			if(_id !== undefined && KL !== undefined){
				this.loadKL = true;
				this.qrSaveKL(_id,KL).then(resp=>{
					return resp.json();
				}).then(json=>{
					const {success,error} = json;
					if(success){
						const index = this.items.findIndex(f=>f._id === _id);
						this.items[index].KL = KL;
						this.notice.success('Đã cập nhật thông tin thành công !!!');
						this.editMode.KL = false;
						this.editData = {};
					}
					if(error){
						this.notice.error(error);
					}
				})
				.catch(err=>{
					console.log(err);
					this.notice.error(err.message || 'Không thể cập nhật thông tin !!!');
				})
				.finally(()=>{
					this.loadKL = false;
				})
			}
			else{
				this.notice.error('Dữ liệu không hợp lệ !!!');
			}
		},
		doUpdateKL(item){
			this.editData = JSON.parse(JSON.stringify(item));
			this.editMode.KL = true;
		},
		doDownloadList(isCleanReload=false){
			this.Selected = [];
			this.loading = true;
			let query = isCleanReload === false ? serialize(this.filter):'';
      PartnerPending('?'+query).then(res => {
				const {success, data, error, total} = res;
				this.items = data;
			}).catch(err => {
				console.log(err);
			}).finally(() => {
				this.loading = false;
			});
		}
	},
	mounted() {
		const {isAllowedPartner} = this;
		if(isAllowedPartner === false || isAllowedPartner['Partner'] !== true){
			this.$router.push('/');
			return;
		}
		this.doDownloadList(true);
	}
}
</script>

<style scoped>

</style>